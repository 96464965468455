import dynamic from 'next/dynamic'

const brand = String(process.env.APP_BRAND).toUpperCase()

const CosmoBoxMenuComponent = dynamic(() => import('@tkww/cosmobox').then((m) => m.CosmoBoxMenu), {
  ssr: false,
})

export const CosmoBox = () => {
  return /(development|foundation-staging|qa)/i.test(String(process.env.APP_ENV)) ? (
    <CosmoBoxMenuComponent brand={brand} country="US" useExistingUnionResources />
  ) : (
    <></>
  )
}
